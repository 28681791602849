<script lang="ts">
export default {
  name: 'NavigationTray',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineEmits, defineProps, onBeforeUnmount, ref, watch, withDefaults } from 'vue';
import Avatar from '@/components/atoms/Avatar/Index.vue';
import NavigationItem from '@/components/atoms/NavigationItem/Index.vue';
import AnnouncementsSidebar from '@/components/organisms/AnnouncementsSidebar/Index.vue';
import {
  PrimaryNavLink,
  PrimaryNavLinkGroup,
  SupportAndUpdates,
} from '@/json_schema_types/sidebar_navigation';
import { useI18n } from '@/composables/useI18n';

const { t } = useI18n();

const emit = defineEmits(['closing']);
type Props = {
  headerLinks?: PrimaryNavLink[];
  navItems?: PrimaryNavLinkGroup[];
  open: boolean;
  theme?: 'branded' | 'light';
  isChild?: boolean;
  supportAndUpdates?: SupportAndUpdates;
};
const props = withDefaults(defineProps<Props>(), {
  theme: 'branded',
  open: false,
});

const openChildMenus = ref({});

watch(
  () => props.navItems,
  (newVal) => {
    if (newVal) {
      openChildMenus.value = newVal.reduce((acc, group, groupIndex) => {
        if (group.links) {
          group.links.forEach((link, linkIndex) => {
            if (link.children) {
              const uniqueKey = `group-${groupIndex}-link-${linkIndex}`;
              acc[uniqueKey] = false;
            }
          });
        }
        return acc;
      }, {});
    }
  },
  { immediate: true }
);

const toggleChildMenu = (key: string, value: boolean) => {
  openChildMenus.value[key] = value;
};

onBeforeUnmount(() => {
  emit('closing');
  Object.keys(openChildMenus.value).forEach((key) => {
    openChildMenus.value[key] = false;
  });
});
</script>

<template>
  <Transition name="slide" mode="out-in">
    <div
      v-if="open"
      class="navigation-tray__tray"
      :class="{ 'navigation-tray__tray--light': theme === 'light' }"
    >
      <slot>
        <ul class="navigation-tray__list">
          <template v-if="isChild">
            <NavigationItem :chevron-before="true" :theme="theme">
              <button
                class="navigation-tray__button navigation-tray__button--back"
                @click="emit('closing')"
              >
                {{ t('helpers.buttons.back') }}
              </button>
            </NavigationItem>
          </template>

          <template v-else>
            <NavigationItem
              v-for="(headerLink, headerIndex) in headerLinks"
              :key="`header-link-${headerIndex}`"
            >
              <Avatar
                v-if="headerLink.avatarUrl"
                class="navigation-tray__avatar"
                :src="headerLink.avatarUrl"
              />
              <a
                class="navigation-tray__link"
                :class="{ 'navigation-tray__link--child': isChild }"
                :href="headerLink.url"
              >
                <FontAwesomeIcon
                  v-if="headerLink.icon"
                  :icon="`${headerLink.icon} fa-regular`"
                  size="lg"
                  class="navigation-tray__icon navigation-tray__icon--header fa-fw"
                />
                {{ headerLink.label }}
              </a>
            </NavigationItem>
            <template v-if="supportAndUpdates">
              <NavigationItem>
                <button
                  id="announcements-toggle"
                  class="navigation-tray__link"
                  :class="{ 'navigation-tray__link--child': isChild }"
                >
                  <FontAwesomeIcon
                    :icon="`${supportAndUpdates.icon} fa-regular`"
                    size="lg"
                    class="navigation-tray__icon navigation-tray__icon--header fa-fw"
                  />
                  {{ supportAndUpdates.label }}
                </button>
              </NavigationItem>
              <AnnouncementsSidebar
                :support-only="supportAndUpdates.support_only"
                :admin-owner-user="supportAndUpdates.admin_owner_user"
                :chat-bot-url="supportAndUpdates.chatbot_url"
                :announcements-endpoint="supportAndUpdates.announcements_endpoint"
                :webinars-endpoint="supportAndUpdates.webinars_endpoint"
                :endpoint="supportAndUpdates.endpoint"
              />
            </template>
          </template>

          <template v-for="(linkGroup, groupIndex) in navItems" :key="linkGroup.group_title">
            <NavigationItem v-if="linkGroup.group_title">
              <span>
                <strong>{{ linkGroup.group_title }}</strong>
              </span>
            </NavigationItem>
            <template v-for="(link, linkIndex) in linkGroup.links" :key="link">
              <template v-if="link.children">
                <NavigationItem :chevron-after="true" :theme="theme">
                  <FontAwesomeIcon
                    v-if="link.icon"
                    :icon="`${link.icon} fa-regular`"
                    size="lg"
                    class="p-r-1 fa-fw"
                  />
                  <button
                    class="navigation-tray__button"
                    @click="toggleChildMenu(`group-${groupIndex}-link-${linkIndex}`, true)"
                  >
                    {{ link.label }}
                  </button>
                </NavigationItem>
                <Teleport to="#subnavs">
                  <NavigationTray
                    :is-child="true"
                    :nav-items="link.children"
                    :open="openChildMenus[`group-${groupIndex}-link-${linkIndex}`]"
                    theme="light"
                    @closing="toggleChildMenu(`group-${groupIndex}-link-${linkIndex}`, false)"
                  />
                </Teleport>
              </template>
              <NavigationItem v-else :theme="theme">
                <FontAwesomeIcon
                  v-if="link.icon"
                  :icon="`${link.icon} fa-regular`"
                  size="lg"
                  class="p-r-1 fa-fw"
                />
                <a
                  class="navigation-tray__link"
                  :class="{ 'navigation-tray__link--child': isChild }"
                  :href="link.url"
                >
                  {{ link.label }}
                </a>
              </NavigationItem>
            </template>
            <hr
              v-if="navItems && groupIndex !== navItems.length - 1"
              class="navigation-tray__divider"
            />
          </template>
        </ul>
      </slot>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.navigation-tray__tray {
  position: fixed;
  top: var(--navigation-height);
  left: 0;
  right: 0;
  height: calc(100vh - var(--navigation-height));
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10;
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
  scrollbar-width: none; /* Firefox */
}

.navigation-tray__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0 0 0;
}

.navigation-tray__tray::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navigation-tray__tray--light {
  background-color: #ffffff;
  color: #333333;
  gap: 14px;

  .navigation-tray__link {
    color: #333333;
  }
}

.navigation-tray__button,
.navigation-tray__link {
  width: 100%;
  text-align: left;
  color: var(--navigation-text-color);
  text-decoration: none;
}

.navigation-tray__button--back {
  margin-left: 10px;
}

.navigation-tray__link--child {
  color: #333333;
}

.navigation-tray__icon {
  font-size: 2rem;
  margin: 0 12px 0 0;
}

.navigation-tray__icon--header {
  margin: 0 12px 0 6px;
}

.navigation-tray__avatar {
  margin-right: 10px;
  & ::v-deep span {
    border: 1px solid var(--navigation-text-color);
  }
}

.navigation-tray__divider {
  border: 0;
  display: block;
  width: 100%;
  background-color: #b8c2cc;
  height: 1px;
  margin: 6px 0;
}

#subnavs {
  position: relative;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.8s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
