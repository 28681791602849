import { ref } from 'vue';

interface IUseSideNavCollapseProps {
  initialCollapsedState: boolean;
}

export default function useSideNavCollapse(props: IUseSideNavCollapseProps) {
  const isSideNavCollapsed = ref(props.initialCollapsedState);

  const toggleCollapse = () => {
    isSideNavCollapsed.value = !isSideNavCollapsed.value;
    localStorage.setItem('side-navigation-status', isSideNavCollapsed.value.toString());
  };

  return { isSideNavCollapsed, toggleCollapse };
}
