<script lang="ts">
export default {
  name: 'NavigationHeaderItem',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, useAttrs, computed } from 'vue';

interface Props {
  icon?: string;
  label?: string;
  badgeCount?: number;
  highlighted?: boolean;
}
const props = defineProps<Props>();
const componentElement = useAttrs().href ? 'a' : 'button';
const faIconString = computed(() => {
  return props.highlighted ? `fa-solid ${props.icon}` : `fa-regular ${props.icon}`;
});
</script>

<template>
  <component
    :is="componentElement"
    class="navigation-header-item"
    :class="{ highlighted: highlighted }"
  >
    <slot>
      <FontAwesomeIcon
        v-if="icon"
        :icon="faIconString"
        size="lg"
        class="navigation-header-item__icon fa-fw"
      />
      <span v-if="label">{{ label }}</span>
      <span v-if="badgeCount" class="navigation-header-item__badge badge badge--small badge--red">{{
        badgeCount
      }}</span>
    </slot>
  </component>
</template>

<style lang="scss" scoped>
.navigation-header-item {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--navigation-text-color);
  border-radius: 4px;
  position: relative;

  &:hover {
    background-color: var(--navigation-hover-color);
  }

  &:focus,
  &:active,
  &.highlighted {
    background-color: var(--navigation-highlight-color);
  }

  &__badge {
    position: absolute;
    top: 2px;
    right: -2px;
  }
}
</style>
