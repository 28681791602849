<template>
  <div class="avatar-full-description__horizontal" data-test-id="full-description-container">
    <Avatar
      v-if="avatarUrl"
      data-test-id="avatar-image"
      :size="size"
      class="avatar-full-description__avatar"
      :src="avatarUrl"
      title=""
    />
    <div class="avatar-full-description__name-wrapper">
      <div class="d-flex align-center">
        <BaseIcon
          v-if="isPinned"
          data-test-id="pinned-icon"
          icon-name="thumb-tack"
          type="danger"
          class="avatar-full-description__pin"
        />
        <TextLink v-if="showPath" :href="showPath" data-test-id="fullname-element">
          {{ fullName }}
        </TextLink>
        <span v-else>
          {{ fullName }}
        </span>
      </div>
      <!--TODO: Update to FA icon -->
      <ToolTip
        v-if="onBreak"
        data-test-id="on-break-icon"
        icon="t-ringer-silent-sleeping"
        :title="t('vue_templates.user_profile.user_profile_details.user_on_break_message')"
        type="muted"
      />
      <small v-if="jobTitle" class="text-muted" data-test-id="job-title">
        {{ jobTitle }}
      </small>
      <slot name="additional-description"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Avatar from '@/components/atoms/Avatar/Index.vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import TextLink from '@/components/atoms/TextLink.vue';
import ToolTip from '@/components/atoms/ToolTip.vue';
import { defineComponent } from 'vue';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  name: 'AvatarFullDescription',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
  components: {
    Avatar,
    BaseIcon,
    TextLink,
    ToolTip,
  },
  props: {
    avatarUrl: String,
    isPinned: Boolean,
    showPath: String,
    fullName: String,
    onBreak: {
      type: Boolean,
      default: false,
    },
    jobTitle: String,
    size: {
      type: String,
      default: 'large',
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>

<style scoped lang="scss">
.avatar-full-description__pin {
  height: auto;
  top: auto;
  margin-right: 5px;
}

.avatar-full-description__horizontal {
  display: flex;
  flex-direction: row;
}

.avatar-full-description__avatar {
  margin: 2px 15px 0 0;
  min-width: 35px;
}

.avatar-full-description__name-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
