<script lang="ts">
export default {
  name: 'PopoverMenu',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, onBeforeUnmount, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';

type Position = 'top' | 'bottom' | 'left' | 'right';

const props = defineProps<{
  modelValue: boolean;
  position?: Position;
  toggleElementSelector?: string | string[];
  // Custom styles
  maxWidth?: string;
  maxHeight?: string;
  right?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const popover = ref<HTMLElement | null>();

onClickOutside(popover, (event: PointerEvent) => {
  // Let the toggle element handle popover closing
  if (props.toggleElementSelector) {
    const target = event.target as HTMLElement;
    const selector = Array.isArray(props.toggleElementSelector)
      ? props.toggleElementSelector.join(',')
      : props.toggleElementSelector;
    if (target.classList.contains(selector) || target.parentElement?.classList.contains(selector)) {
      return;
    }
  }
  emit('update:modelValue', false);
});

const handleKeyup = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('update:modelValue', false);
  }
  //TODO: left / right keys if sub menu for accessibility
};

const customStyles = computed(() => ({
  maxWidth: props.maxWidth,
  maxHeight: props.maxHeight,
  right: props.right,
}));

onMounted(() => {
  window.addEventListener('keydown', handleKeyup);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyup);
});
</script>

<template>
  <div
    v-if="modelValue"
    ref="popover"
    class="popover-menu"
    :class="{ [`popover-menu--${position}`]: position }"
    role="listbox"
    tabindex="0"
    :style="customStyles"
  >
    <slot />
  </div>
  <div v-else></div>
</template>

<style lang="scss" scoped>
$min-width: 240px;
$max-width: 300px;
$popover-zindex: 1000;
$popover-offset: calc(100% + 0.5rem);

.popover-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  background: $white;
  position: absolute;
  z-index: $popover-zindex;
  min-width: $min-width;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15), 0px 5px 16px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 0;
  border-radius: 4px;

  &--bottom {
    top: 100%;
    right: 0;
    border-radius: 0 0 4px 4px;
  }

  &--left,
  &--right {
    top: 0;
    z-index: $popover-zindex - 1;
  }

  &--left {
    right: $popover-offset;
    clip-path: inset(0 0 -20px -20px);
  }

  &--right {
    left: $popover-offset;
    clip-path: inset(0 -20px -20px 0);
  }
}
</style>
