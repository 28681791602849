<script lang="ts">
export default {
  name: 'SubNavigation',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import { PrimaryNavLinkGroup } from '@/json_schema_types/sidebar_navigation';
import NavigationLink from './NavigationLink.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';

interface Props {
  sideBarCollapsed: boolean;
  modelValue: boolean;
  navItems: PrimaryNavLinkGroup[];
}

withDefaults(defineProps<Props>(), {
  sideBarCollapsed: false,
  active: false,
});
</script>

<template>
  <div
    v-if="modelValue"
    class="sub-navigation__container"
    data-test-id="sub-navigation-container"
    :class="{
      'sub-navigation__container--collapsed': sideBarCollapsed,
    }"
  >
    <BaseButton
      fa-prepend-icon="fa-solid fa-arrow-left"
      fa-icon-size="sm"
      class="sub-navigation__close-button"
      variant="icon"
      theme="info"
      @click="$emit('update:modelValue', false)"
    />

    <template
      v-for="(navGroup, groupIndex) in navItems"
      :key="`sub-navigation-group-${groupIndex}`"
    >
      <h4
        v-if="navGroup.group_title"
        :id="`subnav-header-${groupIndex}`"
        class="sub-navigation__heading"
      >
        {{ navGroup.group_title }}
      </h4>
      <ul class="sub-navigation__list" :aria-labelledby="`subnav-header-${groupIndex}`">
        <NavigationLink
          v-for="(link, index) in navGroup.links"
          :key="`sub-nav-link-${index}`"
          :link="link"
          theme="light"
          compact
        />
      </ul>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.sub-navigation__collapse {
  padding: var(--side-navigation-item-padding);
}

.sub-navigation__close-button {
  all: unset;
  cursor: pointer;
  margin: 2rem 0.25rem 0;
  padding: 0.5rem 1rem;
}

.sub-navigation__list {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    margin: 0 1rem;
  }
}

.sub-navigation__heading {
  margin: 0 1rem;
  padding: var(--side-navigation-item-padding);
}

.sub-navigation__container {
  position: absolute;
  width: 200px;
  left: 200px;
  height: 100%;
  transition: left 0.3s ease-in;
  z-index: var(--side-navigation-tray-z-index);
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
}

.sub-navigation__container--collapsed {
  left: 60px;
}
</style>
