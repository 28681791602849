<script lang="ts">
const HEX_REGEX = /^(?:[0-9a-fA-F]{3}){1,2}$/;

export default {
  name: 'AddNew',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { AddNewLinkGroup } from '@/json_schema_types/sidebar_navigation';
import { AddNewLink } from '@/json_schema_types/sidebar_navigation';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const { t } = useI18n();

const breakpoints = useBreakpoints(breakpointsTailwind);
const lgAndLarger = breakpoints.greaterOrEqual('lg');

const isAddNewOpen = ref(false);

const props = defineProps({
  addNewItems: {
    type: Array<AddNewLinkGroup>,
    default: () => [],
  },
  highlightColor: {
    type: String,
    default: '#F5FAFF',
    validator: (value: string) => {
      return HEX_REGEX.test(value);
    },
  },
  outlineBorderColor: {
    type: String,
    default: '#B8C2CC',
    validator: (value: string) => {
      return HEX_REGEX.test(value);
    },
  },
});

const HIGHLIGHTED_MENU_LINK_STYLES = Object.freeze({
  backgroundColor: props.highlightColor,
});

const OUTLINED_MENU_LINK_STYLES = Object.freeze({
  backgroundColor: 'transparent',
  border: `1px solid ${props.outlineBorderColor}`,
});

const generateIconAttrs = (link: AddNewLink) => {
  const { icon, icon_primary_color, icon_secondary_color } = link;
  const isDuotone = !!icon_secondary_color;

  return {
    icon: `${isDuotone && 'fa-duotone'} ${icon}`,
    style: {
      '--fa-primary-color': icon_primary_color,
      ...(isDuotone && {
        '--fa-secondary-color': icon_secondary_color,
        '--fa-secondary-opacity': 0.4,
      }),
    },
  };
};
</script>

<template>
  <NavigationHeaderItem
    data-test-id="add-new-menu-button"
    highlighted
    :aria-label="t('vue_templates.add_new_menu.aria_label')"
    :label="lgAndLarger ? addNewItems[0].group_title : undefined"
    aria-haspopup="true"
    :aria-expanded="isAddNewOpen"
    icon="fa-circle-plus"
    aria-controls="add-new-popover-menu"
    class="add-new-menu__button"
    @click.prevent="isAddNewOpen = !isAddNewOpen"
    @keyup.enter="isAddNewOpen = !isAddNewOpen"
  >
  </NavigationHeaderItem>
  <PopoverMenu
    v-if="isAddNewOpen"
    data-test-id="add-new-popover-menu"
    class="add-new__popover-menu"
    position="bottom"
    toggle-element-selector="add-new-menu__button"
    :model-value="isAddNewOpen"
    max-width="830px"
    max-height="1000px"
    right="5rem"
    @update:model-value="isAddNewOpen = $event"
  >
    <div
      v-for="(addNewItem, index) in addNewItems"
      :key="`add-new-button-${index}`"
      data-test-id="add-new-item-group"
      class="add-new__popover-menu-content"
    >
      <span data-test-id="add-new-item-group-title" class="add-new__title">{{
        addNewItem.group_title
      }}</span>
      <div class="add-new__link-container">
        <a
          v-for="link in addNewItem.links"
          :key="`add-new-${link.label}`"
          :href="link.url"
          data-test-id="add-new-item-group-link"
          class="add-new__link"
          :style="index === 0 ? HIGHLIGHTED_MENU_LINK_STYLES : OUTLINED_MENU_LINK_STYLES"
        >
          <FontAwesomeIcon
            v-if="link.icon"
            v-bind="generateIconAttrs(link)"
            data-test-id="add-new-item-group-link-icon"
            class="add-new__link__icon fa-fw"
          />
          <div class="add-new__link__label">{{ link.label }}</div>
        </a>
      </div>
    </div>
  </PopoverMenu>
</template>

<style lang="scss" scoped>
.add-new__popover-menu {
  color: $jet;
}

.add-new__popover-menu-content {
  padding: 1rem 3rem 1.5rem;
}

.add-new__title {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.add-new__link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.add-new__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 4px;
  color: $jet;

  &:hover {
    background-color: #dae9f7 !important;
  }
}

.add-new__link__icon {
  background-color: #f5faff;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0;
  border-radius: 4px;
}
</style>
